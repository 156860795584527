<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- CODE -->
						<v-col cols="4">
							<pui-text-field
								:id="`code-shiptypepa`"
								v-model="model.code"
								:label="$t('form.shiptypepa.code')"
								:disabled="!isCreatingElement"
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="4">
							<pui-text-field
								:id="`description-shiptypepa`"
								v-model="model.description"
								:label="$t('form.shiptypepa.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'shiptypepa-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'shiptypepa',
			allShipTypes: [],
			allShipTypeAis: []
		};
	},
	methods: {
		afterGetData() {
			this.getAllShipTypes();
			this.getShipTypeAis();
			this.model.portauthorityid = window.localStorage.getItem('workingPortAuthorityId');
		},
		async getAllShipTypes() {
			const opts = {
				model: 'shiptypensw'
			};
			const resp = await this.$puiRequests.postRequest('/puisearch', opts);
			if (resp) {
				this.allShipTypes = resp.data.data;
			}
		},

		async getShipTypeAis() {
			const opts = {
				model: 'shiptypeais',
				rows: null,
				page: 1
			};
			const resp = await this.$puiRequests.postRequest('/puisearch', opts);
			if (resp) {
				this.allShipTypeAis = resp.data.data;
			}
		}
	},
	computed: {},
	created() {}
};
</script>
